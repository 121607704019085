import axios from 'axios';
import axiosRetry from 'axios-retry';
import { Api } from './api';
import { createOrder } from './api-graphql';
import { getGeneralApiProblem } from './api-problem';
import { CreateOrderResult } from './api.types';

axiosRetry(axios, { retries: 10, retryDelay: axiosRetry.exponentialDelay });

export class OrderApi {
  private api: Api;

  constructor(api?: Api, token?: string) {
    if (api) this.api = api;
    if (token) this.api.apisauce.setHeader('authorization', `Bearer ${token}`);
  }

  /**
   * Create an order.
   */
  createOrder(data: namespace.Order): Promise<CreateOrderResult> {
    return this.api.apisauce.post('', createOrder(data)).then((response) => {
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      if (response.data.errors) {
        return { kind: 'bad-data', data: response.data.errors };
      }
      const order = response.data.data;
      return {
        kind: 'ok',
        order
      };
    });
  }

  makePesaPalPayment = (data) => {
    return axios
      .post(
        `${
          process.env.GATSBY_TUTADOO_NET || 'https://dev.tutadoo.com'
        }/api/payment/PesaPalUrlRequest`,
        data
      )
      .then((res) => {
        return res.data;
      });
  };

  /**
   * Pay with mpesa request
   */
  payWithMPESA(data: { amount: number; customerNo: string; reference: string }): Promise<any> {
    return this.api.apisauce
      .post<{ data: { data: { payWithMPESA: any } }; errors: any }, any>('', {
        variables: {
          data
        },
        query: `query payWithMPESA($data: Payment!) {
          payWithMPESA(data: $data) {
            data
            url
            message
            error
          }
        }`
      })
      .then((response) => {
        if (!response.ok) {
          const problem = getGeneralApiProblem(response);
          if (problem) return problem;
        }
        if (response.data.errors) {
          return { kind: 'bad-data', data: response.data.errors };
        }
        return {
          kind: 'ok',
          data: response.data.data.payWithMPESA
        };
      });
  }
}
