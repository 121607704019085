import { useQuery } from '@apollo/client';
import EventHeader from 'components/EventHeader';
import EventAnimation from 'components/EventAnimation';
import Layout from 'components/Layout';
import React, { useEffect, useState } from 'react';
import { getOrder } from 'services/hasura';
import { navigate } from 'gatsby';
import { useQueryParam, StringParam } from 'use-query-params';
import Cart from 'components/Cart';
import Payment from 'components/Payment';
import { Box } from 'grommet/components/Box';
// import RavePayment from 'components/RavePayment';
import PesaPalPayment from 'components/PesaPalPayment';
import { SEO } from 'components/Seo';
import { useInterval } from '../../hooks/useInterval';

export default function complete() {
  const [id, setID] = useQueryParam('id', StringParam);
  const [mode, setMode] = useQueryParam('mode', StringParam);
  const [order, setOrder] = useState<namespace.Order>({ status: null });
  const [orderStatus, setOrderStatus] = useState('');

  const [items, setItems] = useState([]);

  const resp = useQuery(getOrder(id), {
    // pollInterval: 5000,
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (resp.loading) return;
    setOrder(resp.data?.order);
    setOrderStatus(resp.data?.order.status);
    setItems(resp.data?.order.items);
  }, [resp]);

  useEffect(() => {
    if (orderStatus === 'paid') {
      navigate(`/booking/complete/?id=${order.id}`, {
        replace: true
      });
    }
  }, [orderStatus]);

  useInterval(async () => {
    const data = await resp.refetch();
    setOrder(data.data?.order);
    setOrderStatus(data.data?.order.status);
    setItems(data.data?.order.items);
  }, 5000);
  return (
    <Layout>
      {resp.loading && order.status === null ? (
        <EventAnimation />
      ) : (
        <Box width={{ max: 'xlarge' }} margin={{ horizontal: 'auto' }}>
          {order?.event && <EventHeader event={order.event} />}
          {order && (
            <Box direction="row-responsive" gap="large" pad={{ vertical: 'small' }}>
              <Box width="smalllarge">
                {order.event?.event_type === 'streamed' || mode === 'pesapal' || mode === 'card' ? (
                  <PesaPalPayment order={order} />
                ) : (
                  <Payment order={order} />
                )}
              </Box>
              <Box width="smalllarge">
                <Cart order={order} items={items} hideFooter day={order.day} days={order.days} />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Layout>
  );
}

export const Head = () => {
  return <SEO title="Tutadoo | Payment" />;
};
