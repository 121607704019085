import React, { useState } from 'react';
import Col from 'components/Col';
import Row from 'components/Row';
import Text from 'components/Text';
import Button, { ActionButton } from 'components/Button';
import { BaseForm, FormHolder } from 'components/Form';
import { OrderApi } from 'services/api/order-api';
import PhoneNumber from 'awesome-phonenumber';
import tw from 'twin.macro';
import styled, { css } from 'styled-components';
import Modal from 'components/Modal';
// eslint-disable-line import/no-extraneous-dependencies
import { navigate } from 'gatsby';
import mpesa from '../assets/mpesa.png';

const Spinner = styled.svg(() => [tw`animate-spin -ml-1 mr-3 h-14 w-14 text-purple-800 my-4`]);
const IframeContainer = styled.div`
  width: 80vw;
  height: 80vh;
`;

export default function PesaPalPayment({ order }: { order: namespace.Order }) {
  const [loading, setLoading] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [url, setUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const orderAPI = new OrderApi();
  const onPayment = async (data) => {
    setLoading(true);
    const pn = new PhoneNumber(data.phone, 'KE');
    try {
      const resp = await orderAPI.makePesaPalPayment({
        Amount: order.total,
        CustomerNo: pn.getNumber('e164').replace('+', ''),
        Reference: `${order.id}`
      });
      if (resp.message === 'success') {
        setWaiting(true);
        setUrl(resp.data?.url);
        setShowModal(true);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <>
      <Col>
        {/* <img src={mpesa} alt="mpesa" className="w-24" /> */}
        <Text label="Pay with PesaPal" bold medium />
        {waiting && (
          <Col>
            <Text label="Please complete your payment" large />
            <Text label="Do not leave this page" small />
            <Spinner xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle
                css={css`
                  ${tw`opacity-25`}
                `}
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                css={css`
                  ${tw`opacity-75`}
                `}
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </Spinner>
          </Col>
        )}
        {!waiting && (
          <FormHolder onSubmit={onPayment}>
            {({ methods }) => (
              <Col>
                <BaseForm
                  pages={[
                    {
                      elements: [
                        {
                          isRequired: true,
                          name: 'phone',
                          placeholder: 'Please enter your phone number',
                          defaultValue: '',
                          label: 'Phone Number',
                          full: true
                        }
                      ]
                    }
                  ]}
                  defaultValues={{}}
                  state={{ data: {} }}
                  methods={methods}
                />
                <Row centered>
                  <ActionButton primary bold full loading={loading}>
                    Continue
                  </ActionButton>
                </Row>
              </Col>
            )}
          </FormHolder>
        )}
      </Col>
      {showModal && url && (
        <Modal
          closeModal={() => {
            setShowModal(false);
            navigate(`/booking/choose-payment/?id=${order.id}`);
          }}
        >
          <Row spread>
            <Button
              onClick={() => {
                setShowModal(false);
                navigate(`/booking/choose-payment/?id=${order.id}`);
              }}
              bold
              large
              label="✗"
            >
              ✗
            </Button>
          </Row>
          <iframe src={url} className="h-screen" />
          {/* <IframeContainer dangerouslySetInnerHTML={{ __html: `<iframe src='${url}' />` }} /> */}
        </Modal>
      )}
    </>
  );
}
