/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import Col from 'components/Col';
import Row from 'components/Row';
import Text from 'components/Text';
import Button, { ActionButton } from 'components/Button';
import { BaseForm, FormHolder } from 'components/Form';
import { OrderApi } from 'services/api/order-api';
import PhoneNumber from 'awesome-phonenumber';
import tw from 'twin.macro';
// eslint-disable-line import/no-extraneous-dependencies
import styled from 'styled-components';
import { useApi } from 'providers/ApiProvider';
import { useAuth0 } from '@auth0/auth0-react';
import mpesa from '../assets/mpesa.png';

const Spinner = styled.svg(() => [tw`animate-spin -ml-1 mr-3 h-14 w-14 text-purple-800 my-4`]);

export default function Payment({ order, enablePaybill }: { order: namespace.Order }) {
  const [loading, setLoading] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const { api } = useApi();
  const { getIdTokenClaims } = useAuth0();

  const onPayment = async (data) => {
    const claims = await getIdTokenClaims();
    // eslint-disable-next-line no-underscore-dangle
    const orderAPI = new OrderApi(api, claims ? claims.__raw : null);
    setLoading(true);
    const pn = new PhoneNumber(data.phone, 'KE');
    try {
      const resp = await orderAPI.payWithMPESA({
        amount: order.total,
        customerNo: pn.getNumber('e164').replace('+', ''),
        reference: `${order.id}`
      });

      if (resp.data.message === 'success') {
        setWaiting(true);
      } else if (resp.data.message === 'error') {
        window.alert(
          'We apologize for the inconvenience. Our payment partner is experiencing delays. Please try again in a few minutes.'
        );
      } else if (resp.kind === 'bad-data') {
        window.alert(
          "We couldn't complete your request to pay. Please try again in a few minutes."
        );
      }
    } catch (error) {
      window.alert(
        'We apologize for the inconvenience. Our payment partner is experiencing delays. Please try again in a few minutes.'
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (waiting && order.status === 'fail') {
      window.alert('Request failed or cancelled. Please try again');
      setWaiting(false);
    }
  }, [order.status, order.updated_at]);
  return (
    <div>
      <Text
        label={
          <div className="flex flex-row items-center gap-2">
            <span> Pay with</span>
            <img src={mpesa} alt="M-Pesa" className="w-24 pt-2" />
          </div>
        }
        bold
        medium
      />
      {waiting && (
        <Col>
          <Text label="Please confirm your payment on your phone" large />
          <Text label="Do not leave this page" small />

          <Spinner xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle
              css={css`
                ${tw`opacity-25`}
              `}
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              css={css`
                ${tw`opacity-75`}
              `}
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </Spinner>
        </Col>
      )}
      {!waiting && (
        <div>
          <FormHolder onSubmit={onPayment}>
            {({ methods }) => (
              <div className="my-4 p-6 rounded-lg bg-gray-100 space-y-4">
                <Text
                  label="Enter your M-Pesa phone number below and you will be prompted for your M-Pesa PIN on your phone"
                  small
                />
                <BaseForm
                  pages={[
                    {
                      elements: [
                        {
                          isRequired: true,
                          name: 'phone',
                          placeholder: 'Enter your MPESA phone number',
                          defaultValue: '',
                          label: 'M-PESA Phone Number',
                          full: true
                        }
                      ]
                    }
                  ]}
                  defaultValues={{}}
                  state={{ data: {} }}
                  methods={methods}
                />
                <Row className="gap-4 items-center">
                  <ActionButton primary full loading={loading}>
                    Pay with phone
                  </ActionButton>
                  <a href={`/booking/choose-payment/?id=${order.id}`}>cancel</a>
                </Row>
              </div>
            )}
          </FormHolder>
        </div>
      )}
      {enablePaybill && (
        <div className="space-y-2 p-6 bg-gray-100 rounded-lg my-4">
          {/* <Text label="Paybill" bold medium /> */}
          <Text small label={<span>Pay with Lipa na Mpesa</span>} />
          <div className="flex flex-row gap-2">
            <Text label="MPESA Paybill Number:" bold />
            <Text label={<span className="font-bold">4092821</span>} />
          </div>
          <div className="flex flex-row gap-2">
            <Text label="ACCOUNT NO:" bold />
            <Text label={<span className="font-bold">{order.id}</span>} />
          </div>
          <div className="flex flex-row gap-2">
            <Text label="AMOUNT:" bold />
            <Text
              label={
                <span className="font-bold">
                  {order.currency || 'KES'} {order.total}
                </span>
              }
            />
          </div>
          <a href="/#" className="text-sm text-primary underline">
            Click here if your balance has been deducted
          </a>
        </div>
      )}
    </div>
  );
}
